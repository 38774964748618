@import 'assets/scss/variables.module.scss';

$info-hover-border: 1px solid $light-background2;
$border-radius: 5px;
$avatar-size: 40px;

.container {
  box-shadow: $generic-box-shadow;
  transition: opacity 0.2s ease;
  border-radius: $border-radius;
  border: 1px solid $light-color2;
}

.thumbnail {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: $border-radius $border-radius 0 0;

  &:hover {
    .previewImage {
      scale: 1.125;
    }
  }
}

.loading {
  animation: breathing 1s ease-out infinite normal;
}

@keyframes breathing {
  0% {
    background: $light-background;
  }

  50% {
    background: $light-background2;
  }

  100% {
    background: $light-background;
  }
}

.previewImage {
  height: 175px;
  border-radius: $border-radius 0;
  object-fit: cover;
  position: relative;
  transition: scale 0.4s ease;
  scale: 1.1;

  img {
    min-height: 185px;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.views {
  position: absolute;
  bottom: 34px;
  right: 18px;
  color: $light-color;
  font-size: 12px;
}

.descriptionIcon {
  position: absolute;
  bottom: 34px;
  left: 18px;
  color: $light-color;
  font-size: 12px;
}

.bar {
  position: absolute;
  bottom: 10px;

  height: 20px;
  border-radius: 3px;
  overflow: hidden;

  i {
    font-size: 12px;
  }
}

.gradientMask {
  position: absolute;

  width: 100%;
  height: 85px;

  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  transition: background 0.25s ease-in;
}

.topGradientMask {
  @extend .gradientMask;

  top: 0;
  height: 75px;

  background: linear-gradient(180deg, $framing-color 0, rgba(161, 161, 161, 0) 85%);
}

.bottomGradientMask {
  @extend .gradientMask;

  height: 145px;

  bottom: 0;
  background: linear-gradient(180deg, rgba(161, 161, 161, 0) 0%, $light-background 78%);
}

.infoContainer {
  display: flex;
  flex-wrap: nowrap;

  cursor: pointer;

  padding: 0 8px;
  background-color: $light-background;

  height: 75px;

  border-radius: 0 0 $border-radius $border-radius;
}

.avatar {
  height: 100%;
  margin-top: 10px;

  img {
    width: $avatar-size;
    height: $avatar-size;
  }
}

.header {
  font-weight: bold;
  font-size: 16px;
  display: block;

  height: 25px;
  overflow: hidden;

  a {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: $color;
      text-decoration: underline;
    }
  }
}

.info {
  padding: 0 8px;
  width: 100%;
  max-width: calc(100% - 75px);
}

.subInfo {
  padding-top: 15px;
  color: $light-color;

  a {
    transition: color 0.2s ease;

    &:hover {
      color: $archidekt-orange;
    }
  }
}

.subSubInfo {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  & > * {
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      width: 13px;
      height: 13px;
    }
  }
}

.options {
  width: 200px;
}

.optionsTrigger {
  i {
    margin-right: 0;
  }
}

.bookmarked {
  i {
    color: $archidekt-orange;
  }
}

.tags {
  display: flex;
  gap: 0.25rem;
  padding: 2px 23px;

  color: $color;
  background-color: $light-background;
  border-top: 1px solid $light-color2;

  overflow: hidden;

  i {
    margin-right: 13px !important;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  a {
    font-weight: bold;
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover {
      color: $archidekt-orange;
    }
  }
}

.noTags {
  @extend .tags;

  padding: 2px 0;
  width: 100%;

  font-weight: 300;

  display: flex;
  justify-content: center;
}

.deckGridUnlocked {
  display: grid;
  justify-items: center;
  gap: 25px;

  & > * {
    width: 100%;
    max-width: 325px;
    min-width: 260px;
  }

  grid-template-columns: repeat(5, 1fr);

  @include response-large-to-x-large {
    grid-template-columns: repeat(4, 1fr);
  }

  @include response-medium-to-large {
    grid-template-columns: repeat(3, 1fr);
  }

  @include response-small-to-medium {
    grid-template-columns: repeat(2, 1fr);

    & > * {
      max-width: 90%;
    }
  }

  @include response-small {
    grid-template-columns: repeat(1, 1fr);
  }
}

.deckGrid {
  @extend .deckGridUnlocked;

  & > * {
    display: none; // Hide all by default then show them based on how many items you want to show vs the max number of rows you want
  }

  @include response-min-x-large {
    & > *:nth-child(-n + 10) {
      display: unset;
    }
  }

  @include response-large-to-x-large {
    & > *:nth-child(-n + 8) {
      display: unset;
    }
  }

  @include response-medium-to-large {
    & > *:nth-child(-n + 9) {
      display: unset;
    }
  }

  @include response-small-to-medium {
    & > *:nth-child(-n + 8) {
      display: unset;
    }
  }

  @include response-small {
    & > *:nth-child(-n + 8) {
      display: unset;
    }
  }
}

.tagChips {
  position: absolute;
  top: 0;

  display: flex;
  flex-wrap: wrap;
  gap: 3px;

  padding: 6px;

  span {
    padding: 1px 5px;
    background-color: $archidekt-orange;
    font-weight: bold;
    white-space: nowrap;
    border-radius: 5px;
    box-shadow: $generic-box-shadow;
    color: white;

    &:hover {
      color: white; // Overwritting parent hover styles
    }
  }
}

body:global(.dark-mode) .invertable {
  filter: invert(1);
}

.disableCursor {
  pointer-events: none !important;
}

.semanticRoleIcon {
  color: $color;
  margin-right: 0 !important;
  margin-bottom: 7px !important;
}

.format {
  white-space: nowrap;
}
